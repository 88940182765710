<template>
    <div class="modal-notify modal-content">
        <button v-if="!hideXButton" @click="$emit('close')" type="button" data-dismiss="modal" aria-label="Close" class="close"
                style="width: 30px; height: 30px; border-radius: 30px; font-size: 30px; background: gray;"><span
                aria-hidden="true">×</span></button>
        <h2 class="modal-title title-coupons lg-cond text-center pt-10 pb-10">
            {{ title }}
        </h2>
        <div class="free-menu-description section-content--free-menu t13 text-black text-center">
            {{ message }}
        </div>
        <button type="button" @click="$emit('close')"
                class="btn btn-success button-coupon-text t16 fw600">{{$t('close')}}
        </button>
    </div>
</template>

<script>
export default {
  props: ['title', 'message', 'name', 'hideXButton'],
  data () {
    return {

    }
  },
  watch: {},
  mounted () {

  },
  methods: {}
}
</script>

<style scoped>
    .close {
        margin-top: 0;
    }

    button {
        margin: auto;
        width: 100%;
        margin-top: 20px;
    }

    .modal-notify {
        max-width: 400px;
        margin: auto;
        background: white;
        padding: 15px;
    }

    .alert-danger2 {
        color: #a94442;
        font-weight: bold;
    }

    .free-menu-description {
        cursor: pointer;
    }

    .v--modal-box {
        height: auto !important;
        top: 50px !important;
    }

    .modal-coupon .v--modal {
        min-width: 0px !important;
    }
</style>
